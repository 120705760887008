<template>
  <v-container
    :id="`quote-form-trip-${tripIndex}-payment`"
    class="form-trip-payment"
  >
    <v-layout v-if="displayAutomatedPricing">
      <!-- <v-layout> -->
      <v-form ref="payment-select-table" style="width: 100%">
        <QuotesAutomatedPricingLayout
          :quote="quote"
          :trip-index="tripIndex"
          :set-fare="setFare"
          :clear-pricing-table="clearPricingTable"
          :use-table="!useSinglePriceSelector || managerOverrideUsed"
          @pricing-data="$emit('pricing-data', $event)"
          @pricing-table-cleared="$emit('pricing-table-cleared')"
        />
      </v-form>
    </v-layout>
    <v-layout v-else>
      <!-- <v-layout> -->
      <v-flex xs5>
        <h3>Overage Rates</h3>
        <v-layout column class="quote-form-payment-rates">
          <v-flex
            v-for="(rate, rateIndex) in rates"
            :id="`trip-${tripIndex}-rate-${rateIndex}`"
            :key="`trip-${tripIndex}-rate-${rateIndex}`"
          >
            <v-form ref="trip-rate-detail-form">
              <v-flex class="layout text-field-row padded">
                <CRSelect
                  :id="`quote-form-trip-${tripIndex}-rate-${rateIndex}-type`"
                  :value="rate.rateType.id"
                  class="flex xs7"
                  :items="rateTypes"
                  item-value="id"
                  item-text="label"
                  :disabled="isModeView"
                  :rules="
                    formSubmitted
                      ? [
                          isRequired(true, isNotEmpty, {
                            req: 'Rate Type Is Required',
                            error: 'Rate Type Is Required',
                          }),
                        ]
                      : []
                  "
                  @input="setRateTypeId(rateIndex, $event)"
                />
                <CRInput
                  :id="`quote-form-trip-${tripIndex}-rate-${rateIndex}-amount`"
                  :value="rate.amount"
                  class="flex xs4"
                  type="number"
                  prefix="$"
                  :disabled="isModeView"
                  :rules="
                    formSubmitted
                      ? [
                          isRequired(
                            true,
                            () => validateGreaterThanZero(rate.amount),
                            {
                              req: 'Rate Amount Is Required',
                              error: 'Rate Amount Is Required',
                            }
                          ),
                        ]
                      : []
                  "
                  @input="setRateAmount(rateIndex, $event)"
                />
                <a
                  v-if="!isModeView"
                  :id="`quote-form-trip-${tripIndex}-rate-${rateIndex}-remove-button`"
                  class="xs1"
                  style="margin-top: 6px"
                  @click="removeRate(rateIndex)"
                >
                  <CRIcon color="primary">trash</CRIcon>
                </a>
              </v-flex>
            </v-form>
          </v-flex>
        </v-layout>
        <p>
          <a
            v-if="!isModeView"
            :id="`quote-form-trip-${tripIndex}-add-rate-button`"
            @click.stop="addRate"
          >
            + Add Another Rate
          </a>
        </p>
        <h3>Pricing Breakdown</h3>
        <v-layout column class="quote-form-payment-charges">
          <v-flex
            v-for="(charge, chargeIndex) in charges"
            :key="`trip-${tripIndex}-charge-${chargeIndex}`"
          >
            <v-form ref="trip-charge-detail-form">
              <v-flex class="layout padded">
                <CRSelect
                  :id="`quote-form-trip-${tripIndex}-charge-${chargeIndex}-type`"
                  :value="charge.chargeType.id"
                  class="flex xs7"
                  :items="
                    chargeTypes.filter((chargeType) => !chargeType.hidden)
                  "
                  item-value="id"
                  item-text="label"
                  :disabled="
                    isModeView ||
                    (quote.charterUpQuote && charge.chargeType.id === 1)
                  "
                  :rules="
                    formSubmitted
                      ? [
                          isRequired(true, isNotEmpty, {
                            req: 'Charge Type Is Required',
                            error: 'Charge Type Is Required',
                          }),
                        ]
                      : []
                  "
                  @input="setChargeTypeId(chargeIndex, $event)"
                />
                <CRInput
                  v-if="charge.chargeType.id !== 15"
                  :id="`quote-form-trip-${tripIndex}-charge-${chargeIndex}-amount`"
                  :value="charge.amount"
                  class="flex xs4"
                  type="number"
                  prefix="$"
                  :disabled="
                    isModeView ||
                    (charge.chargeType.id === 1 && useSinglePriceSelector)
                  "
                  :rules="
                    formSubmitted
                      ? [
                          isRequired(
                            true,
                            () =>
                              validateGreaterThanZero(charge.amount) ||
                              quote.isPreBooking,
                            {
                              req: 'Charge Amount Is Required',
                              error: 'Charge Amount Is Required',
                            }
                          ),
                        ]
                      : []
                  "
                  @input="setChargeAmount(chargeIndex, $event)"
                />
                <CRInput
                  v-if="charge.chargeType.id === 15"
                  :id="`quote-form-trip-${tripIndex}-charge-${chargeIndex}-amount`"
                  :value="charge.amount"
                  class="flex xs4"
                  type="number"
                  prefix="$"
                  :disabled="isModeView"
                  :rules="
                    formSubmitted
                      ? [
                          isRequired(
                            true,
                            () => validateLessThanZero(charge.amount),
                            {
                              req: 'Discount Amount Must Be Negative Value',
                              error: 'Discount Amount Must Be Negative Value',
                            }
                          ),
                        ]
                      : []
                  "
                  @input="(e) => editDiscount(e, charge)"
                />
                <v-tooltip top>
                  <span>
                    {{
                      useSinglePriceSelector
                        ? 'Manager pricing override'
                        : 'Reset value and return to automatic pricing'
                    }}
                  </span>
                  <template #activator="{ on }">
                    <a
                      v-if="
                        !isModeView &&
                        charge.chargeType.id === 1 &&
                        ((!quote.charterUpQuote && canOpenCharterUpQuote) ||
                          (useSinglePriceSelector && hasManagerOverride))
                      "
                      id="revert-to-table"
                      class="xs1"
                      style="
                        position: relative;
                        left: 2px;
                        margin-top: 6px;
                        margin-left: 6px;
                      "
                      @click="revert(chargeIndex)"
                    >
                      <v-icon
                        v-if="useSinglePriceSelector && hasManagerOverride"
                        color="primary"
                        v-on="on"
                      >
                        $vuetify.icons.adminMoneyIcon
                      </v-icon>
                      <v-icon v-else color="primary" v-on="on">replay</v-icon>
                    </a>
                  </template>
                </v-tooltip>
                <a
                  v-if="!isModeView && chargeIndex > 0"
                  :id="`quote-form-trip-${tripIndex}-charge-${chargeIndex}-remove-button`"
                  class="xs1"
                  style="margin-top: 6px"
                  @click="removeCharge(chargeIndex)"
                >
                  <CRIcon color="primary">trash</CRIcon>
                </a>
              </v-flex>
            </v-form>
          </v-flex>
          <p v-if="!isModeView">
            <a
              v-if="quote.charterUpQuote && !hasMarketplaceDiscount"
              :id="`quote-form-trip-${tripIndex}-add-discount-percent-button`"
              @click="addMarketPlaceDiscount"
            >
              + Add Discount %
            </a>
            <a
              v-else-if="!quote.charterUpQuote"
              :id="`quote-form-trip-${tripIndex}-add-charge-button`"
              @click.stop="addCharge"
            >
              + Add Another Charge
            </a>
          </p>
          <p
            v-if="
              !isModeView && quote.charterUpQuote && !marketplaceMarkupAdded
            "
          >
            <a
              :id="`quote-form-trip-${tripIndex}-add-markup-percent-button`"
              @click="addMarketPlaceMarkup"
            >
              + Add Markup %
            </a>
          </p>
        </v-layout>

        <h3 v-if="hasMarketplaceDiscount">Marketplace Discount</h3>
        <v-layout
          v-if="hasMarketplaceDiscount"
          column
          class="quote-form-payment-charges"
        >
          <v-layout padded>
            <v-flex xs7>
              <CRInput
                :id="`quote-form-trip-${tripIndex}-processing-fee`"
                v-model="tripData.marketplaceDiscountPercent"
                mask="###"
                type="number"
                suffix="%"
                min="0"
                max="100"
                step="1"
                :disabled="isModeView"
                @input="constrainDiscountPercent"
              />
            </v-flex>
            <v-flex xs1>
              <a
                v-if="!isModeView"
                :id="`quote-form-trip-${tripIndex}-marketplace-discount-remove-button`"
                style="margin-top: 6px"
                @click="removeMarketplaceDiscount"
              >
                <CRIcon color="primary">trash</CRIcon>
              </a>
            </v-flex>
          </v-layout>
        </v-layout>
        <h3 v-if="hasMarketplaceMarkup">Marketplace Markup</h3>
        <v-layout
          v-if="hasMarketplaceMarkup"
          column
          class="quote-form-payment-charges"
        >
          <v-layout padded>
            <v-flex xs7>
              <CRInput
                :id="`quote-form-trip-${tripIndex}-markup-percentage`"
                v-model="tripData.marketplaceMarkupPercent"
                mask="###"
                type="number"
                suffix="%"
                min="0"
                max="100"
                step="1"
                :disabled="isModeView"
                @input="constrainMarkupPercent"
              />
            </v-flex>
            <v-flex xs1>
              <a
                v-if="!isModeView"
                :id="`quote-form-trip-${tripIndex}-marketplace-markup-remove-button`"
                style="margin-top: 6px"
                @click="removeMarketplaceMarkup"
              >
                <CRIcon color="primary">trash</CRIcon>
              </a>
            </v-flex>
          </v-layout>
        </v-layout>
        <h3>Processing Fee</h3>
        <v-form ref="trip-processing-fee-charge-detail-form">
          <v-layout column class="quote-form-payment-charges">
            <v-flex class="layout padded">
              <CRInput
                :id="`quote-form-trip-${tripIndex}-processing-fee`"
                :value="processingFeePercentage"
                mask="###"
                suffix="%"
                class="flex xs7"
                type="number"
                min="0"
                max="100"
                step="1"
                :disabled="isModeView"
                :rules="
                  formSubmitted
                    ? [
                        isRequired(
                          true,
                          () => isNumber(processingFeePercentage),
                          {
                            req: 'Processing Fee Is Required',
                            error: 'Processing Fee Is Required',
                          }
                        ),
                      ]
                    : []
                "
                @input="$emit('update-trip-processing-percentage', $event)"
              />
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>

      <v-spacer xs1 />

      <v-flex xs6>
        <div class="infobox">
          Trip Subtotal
          <span class="right">
            {{ filterCurrency(subtotal) }}
            <span v-if="currencyType !== 'USD'">
              ({{ filterCurrency(subtotal * exchangeRate) }} {{ currencyType }})
            </span>
          </span>
        </div>
        <div class="infobox">
          Processing Fee ({{ processingFeePercentage }}%)
          <span class="right">
            {{ filterCurrency(processingFeeTotal) }}
            <span v-if="currencyType !== 'USD'">
              ({{ filterCurrency(processingFeeTotal * exchangeRate) }}
              {{ currencyType }})
            </span>
          </span>
        </div>
        <div v-show="hasMarketplaceDiscount" class="infobox">
          Marketplace Discount ({{ tripData.marketplaceDiscountPercent || 0 }}%)
          <span class="right">
            {{ filterCurrency(marketplaceDiscountTotal) }}
          </span>
        </div>
        <div
          v-show="quote.charterUpQuote && hasMarketplaceMarkup"
          class="infobox"
        >
          Marketplace Markup ({{ tripData.marketplaceMarkupPercent || 0 }}%)
          <span class="right">
            {{ filterCurrency(marketplaceMarkupTotal) }}
          </span>
        </div>
        <div class="infobox dark">
          Trip Total
          <span class="right">
            {{ filterCurrency(total) }}
            <span v-if="currencyType !== 'USD'">
              ({{ filterCurrency(total * exchangeRate) }} {{ currencyType }})
            </span>
          </span>
        </div>
        <div><br /></div>
        <v-form ref="trip-payment-totals-form">
          <v-layout>
            <v-flex xs4>
              <CRSelect
                :id="`quote-form-trip-${tripIndex}-payment-type`"
                :value="paymentTypeId"
                label="Type of Payment"
                :items="paymentTypes"
                item-text="label"
                item-value="id"
                :disabled="isModeView"
                :rules="
                  formSubmitted
                    ? [
                        isRequired(true, isNotEmpty, {
                          req: 'Type of Payment Is Required',
                          error: 'Type of Payment Is Required',
                        }),
                      ]
                    : []
                "
                @input="paymentTypeChanged"
                @change="disableAutoSetPaymentType"
              />
            </v-flex>
            <v-flex xs1 />
            <v-flex xs7>
              <v-layout row wrap>
                <v-flex xs12>
                  <label>Allowed methods of payment</label>
                </v-flex>
                <v-flex xs12>
                  <div
                    v-for="(paymentMethod,
                    paymentMethodIndex) in paymentMethods"
                    :key="`trip-${tripIndex}-payment-methods-${paymentMethodIndex}`"
                    ref="paymentMethodCheckBoxes"
                    class="payment-method-checkbox-container"
                  >
                    <input
                      :id="`quote-form-trip-${tripIndex}-payment-method-${paymentMethodIndex}`"
                      v-model="paymentMethod.isAllowed"
                      :name="`quote-form-trip-${tripIndex}-payment-method-${paymentMethodIndex}`"
                      type="checkbox"
                      :disabled="isModeView"
                      @change="
                        checkPaymentMethodSelections(paymentMethodIndex, $event)
                      "
                    />
                    <label
                      :for="`quote-form-trip-${tripIndex}-payment-method-${paymentMethodIndex}`"
                      class="checkbox-label"
                    >
                      {{ paymentMethod.paymentMethodType.label }}
                    </label>
                  </div>
                </v-flex>
              </v-layout>

              <span v-if="showPaymentMethodError" class="error-message">
                Method Of Payment Is Required
              </span>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs4>
              <CRInput
                v-if="[2, 3].includes(paymentTypeId)"
                :id="`quote-form-trip-${tripIndex}-payment-due-date`"
                :value="dueDate"
                label="Balance Due Date"
                type="date"
                :disabled="isModeView"
                :rules="
                  formSubmitted
                    ? [
                        isRequired(true, isNotEmpty, {
                          req: 'Due Date Is Required',
                          error: 'Due Date Is Required',
                        }),
                      ]
                    : []
                "
                @input="setDueDate($event)"
                @change="disableAutoSetPaymentType"
              />
            </v-flex>
            <v-spacer />
            <v-flex xs3>
              <CRInput
                v-if="paymentTypeId === 2"
                :id="`quote-form-trip-${tripIndex}-payment-deposit-percentage`"
                :value="depositPercentage"
                label="Percentage"
                type="number"
                suffix="%"
                min="0"
                max="100"
                step="1"
                :disabled="isModeView"
                :rules="
                  formSubmitted
                    ? [
                        isRequired(
                          true,
                          () =>
                            validateGreaterThanZero(tripData.depositPercentage),
                          {
                            req: 'Percentage Is Required',
                            error: 'Percentage Is Required',
                          }
                        ),
                      ]
                    : []
                "
                @input="setDepositPercentage($event)"
                @change="disableAutoSetPaymentType"
              />
            </v-flex>
            <v-spacer />
            <v-flex xs3>
              <CRInput
                v-if="paymentTypeId === 2"
                :value="filterCurrency(requiredDeposit)"
                label="Amount"
                :readonly="true"
                type="text"
                :disabled="isModeView"
                :rules="
                  formSubmitted
                    ? [
                        isRequired(
                          true,
                          () =>
                            validateGreaterThanZero(tripData.depositPercentage),
                          {
                            req: 'Percentage Is Required',
                            error: 'Percentage Is Required',
                          }
                        ),
                      ]
                    : []
                "
              />
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <div style="margin-top: 10px">
      <QuoteFormCalculations
        :trip-data="tripData"
        :toggled="false"
        :trip-index="tripIndex"
        :live-mile-label="liveMileLabel"
        :dead-mile-label="deadMileLabel"
        :estimated-time-label="estimatedTimeLabel"
        :calculating-estimations="calculatingEstimations"
        :quote="quote"
        :mode="mode"
      />
    </div>
  </v-container>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import QuoteFormCalculations from './QuoteFormCalculations.vue'
import QuotesAutomatedPricingLayout from './QuotesAutomatedPricingLayout.vue'

import { mapActions, mapGetters } from 'vuex'
import { deepClone } from '@/utils/deepClone'
import { currencyFilter } from '@/utils/currency'
import { authComputed } from '@/state/helpers'
import {
  isNotEmpty,
  isRequired,
  isNumber,
  validateGreaterThanZero,
  validateTripFormGroups,
  validateLessThanZero,
} from '@/utils/validators'
import { scrubQuoteForAPI } from '@/utils/quoteUtils'
import { EventBus } from '@/utils/event-bus'
import { ChargeTypeId } from '@/utils/enum'

export default {
  components: {
    QuoteFormCalculations,
    QuotesAutomatedPricingLayout,
  },
  model: {
    prop: 'tripData',
  },
  props: {
    canOpenCharterUpQuote: { type: Boolean },
    toggled: { type: Boolean, default: false },
    calculatingEstimations: { type: Boolean, default: false },
    validationKey: { type: String, default: undefined },
    dueDateKey: { type: String, default: undefined },
    tripIndex: { type: Number, default: null },
    exchangeRate: { type: Number, default: 1 },
    currencyType: { type: String, default: 'USD' },
    paymentTypes: { type: Array, default: () => [] },
    chargeTypes: { type: Array, default: () => [] },
    rateTypes: { type: Array, default: () => [] },
    suggestedPricing: { type: Object, default: () => undefined },
    paymentMethodTypes: { type: Array, default: () => [] },
    tripData: {
      type: Object,
      default: () => ({ rates: [], charges: [], stops: [] }),
    },
    mode: { type: String, default: undefined },
    quote: { type: Object, default: () => ({}) },
    estimatedTimeLabel: { type: String, default: '' },
    deadMileLabel: { type: String, default: '' },
    liveMileLabel: { type: String, default: '' },
    clearPricingTable: {
      type: Boolean,
    },
    pricingData: {
      type: Array,
      default: () => [],
    },
    refreshPricing: { type: Boolean },
    showMarketplaceFeatures: {
      type: Boolean,
    },
    quoteDefaults: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      hasNotUsedAutoPrice: true,
      managerOverrideUsed: false,
      isNotEmpty,
      isRequired,
      validateGreaterThanZero,
      validateLessThanZero,
      isNumber,
      filterCurrency: currencyFilter,
      formSubmitted: false,
      showPaymentMethodError: false,
      marketplaceDiscountAdded: false,
      marketplaceMarkupAdded: false,
      discountDebounce: null,
      markupDebounce: null,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      recalculatePricing: 'quotes/getShouldRecalculatePricing',
      isShuttleCreateQuoteEnabled: 'featureToggles/isShuttleCreateQuoteEnabled',
    }),
    isModeView() {
      return this.mode === 'view'
    },
    currentTrip() {
      return this.$store.getters['quoteForm/getCurrentTrip']
    },
    paymentTypeId() {
      return this.tripData?.paymentType?.id || this.currentTrip?.paymentType?.id
    },
    processingFeePercentage() {
      if (
        this.tripData?.processingFeePercentage === null ||
        this.tripData?.processingFeePercentage === undefined
      ) {
        return this.currentTrip?.processingFeePercentage
      }
      return this.tripData?.processingFeePercentage
    },
    paymentMethods() {
      return this.tripData?.paymentMethods || this.currentTrip?.paymentMethods
    },
    charges() {
      return this.currentTrip?.charges || this.tripData?.charges
    },
    rates() {
      return this.currentTrip?.rates || this.tripData?.rates
    },
    dueDate() {
      return this.tripData?.dueDate || this.currentTrip?.dueDate
    },
    depositPercentage() {
      if (
        this.tripData?.depositPercentage === null ||
        this.tripData?.depositPercentage === undefined
      ) {
        return this.currentTrip?.depositPercentage
      }
      return this.tripData?.depositPercentage
    },
    useSinglePriceSelector() {
      return this.quote.charterUpQuote
    },
    displayAutomatedPricing() {
      if (
        this.hasNotUsedAutoPrice &&
        !this.isModeView &&
        this.canOpenCharterUpQuote
      ) {
        return true
      } else {
        return false
      }
    },
    subtotal() {
      const chargeTotal = this.charges.reduce(
        (total, charge) => total + parseFloat(charge.amount || 0),
        0
      )
      return chargeTotal
    },
    processingFeeTotal() {
      const processingFeeCharge =
        this.processingFeePercentage * 0.01 * this.subtotal
      // TODO: fix this side-effect
      // eslint-disable-next-line
      this.tripData.processingFeeCharge = processingFeeCharge
      if (this.isShuttleCreateQuoteEnabled) {
        EventBus.$emit('set-processing-fee-charge', processingFeeCharge)
      }
      return parseFloat(processingFeeCharge)
    },
    marketplaceDiscountTotal() {
      if (this.tripData.marketplaceDiscountPercent > 0) {
        const marketplaceDiscountCharge =
          this.tripData.marketplaceDiscountPercent *
          0.01 *
          (this.subtotal + this.processingFeeTotal) *
          -1
        // TODO: fix this side-effect
        // eslint-disable-next-line
        this.tripData.marketplaceDiscountCharge = marketplaceDiscountCharge
        return parseFloat(marketplaceDiscountCharge)
      }
      return 0
    },
    marketplaceMarkupTotal() {
      if (this.tripData.marketplaceMarkupPercent > 0) {
        const marketplaceMarkupCharge =
          this.tripData.marketplaceMarkupPercent *
          0.01 *
          (this.subtotal + this.processingFeeTotal)
        // TODO: fix this side-effect
        // eslint-disable-next-line
        this.tripData.marketplaceMarkupCharge = marketplaceMarkupCharge
        return parseFloat(marketplaceMarkupCharge)
      }
      return 0
    },
    amenitiesChargeTotal() {
      const amenitiesCharges = this.charges.filter(
        (charge) => charge?.chargeType?.id == ChargeTypeId.Amenities
      )
      const amenitiesChargesTotal = amenitiesCharges.reduce(
        (total, charge) => total + parseFloat(charge.amount || 0),
        0
      )
      return amenitiesChargesTotal
    },
    hasMarketplaceDiscount() {
      return (
        this.marketplaceDiscountAdded ||
        this.tripData.marketplaceDiscountPercent > 0
      )
    },
    hasMarketplaceMarkup() {
      return (
        this.marketplaceMarkupAdded ||
        this.tripData.marketplaceMarkupPercent > 0
      )
    },
    total() {
      const tripTotal =
        this.processingFeeTotal +
        this.marketplaceDiscountTotal +
        this.marketplaceMarkupTotal +
        this.subtotal
      // TODO: fix this side-effect
      // eslint-disable-next-line
      this.tripData.total = tripTotal
      if (this.isShuttleCreateQuoteEnabled) {
        EventBus.$emit('set-trip-total', tripTotal)
      }
      return tripTotal
    },
    requiredDeposit() {
      return this.paymentTypeId === 1
        ? this.total
        : this.total * this.depositPercentage * 0.01
    },
    shouldHitPricingEngine() {
      return (
        this.useSinglePriceSelector &&
        !this.managerOverrideUsed &&
        this.quote.trips[this.tripIndex].tripType.id &&
        this.quote.trips[this.tripIndex]?.requiredVehicles[0]?.quantity &&
        this.quote.trips[this.tripIndex]?.requiredVehicles[0]?.vehicleType.id
      )
    },
    earliestPickupDateTime() {
      const stopToPickupTime = function (stop) {
        const pickupDate = stop.pickupDate
        const pickupTime = stop.pickupTime
        const timeZone = stop.address.timeZone

        return DateTime.fromISO(`${pickupDate}T${pickupTime}`, {
          zone: timeZone,
        })
      }

      const stops = this.tripData?.stops || deepClone(this.currentTrip?.stops)
      const pickupDateTimes = stops
        ?.filter((s) => !!s.pickupDate)
        .map((s) => stopToPickupTime(s))

      let earliestPickupDateTime = null
      for (const pickupDateTime of pickupDateTimes) {
        if (
          earliestPickupDateTime === null ||
          pickupDateTime.toMillis() < earliestPickupDateTime.toMillis()
        ) {
          earliestPickupDateTime = pickupDateTime
        }
      }

      return earliestPickupDateTime
    },
    minPickupDaysAway() {
      if (this.earliestPickupDateTime === null) {
        return Infinity
      }

      return Interval.fromDateTimes(
        DateTime.local(),
        this.earliestPickupDateTime
      ).toDuration('days').days
    },
    isQuotesAutoSetPaymentEnabled() {
      const { getters = {} } = this.$store
      return !getters['systemParameters/isQuotesAutoSetPaymentDisabled']
    },
    canAutomaticallySetPaymentType() {
      return (
        this.isQuotesAutoSetPaymentEnabled &&
        this.showMarketplaceFeatures &&
        this.tripData.autoSetPaymentType
      )
    },
  },
  watch: {
    validationKey() {
      this.validateForms()
    },
    dueDateKey() {
      this.paymentTypeChanged()
    },
    toggled() {},
    tripData: {
      deep: true,
      handler: function () {
        let chargeTotal
        if (this.currentTrip) {
          chargeTotal = this.currentTrip.charges.reduce(
            (total, charge) => total + parseFloat(charge.amount || 0),
            0
          )
          if (!this.currentTrip.charges.length) {
            this.hasNotUsedAutoPrice = true
          }
        } else {
          chargeTotal = this.tripData.charges.reduce(
            (total, charge) => total + parseFloat(charge.amount || 0),
            0
          )
        }
        if (chargeTotal > 0) {
          this.hasNotUsedAutoPrice = false
        }
      },
    },
    'tripData.passengerCount'() {
      this.resetManagerOverridePrice()
    },
    'tripData.requiredDrivers'() {
      this.resetManagerOverridePrice()
    },
    'tripData.requiredVehicles': {
      deep: true,
      handler: function () {
        this.resetManagerOverridePrice()
      },
    },
    'tripData.stops': {
      deep: true,
      handler: async function () {
        this.resetManagerOverridePrice()
        if (this.canAutomaticallySetPaymentType) {
          await this.autoSetPaymentInformation()
        }
        this.setCustomerAccountDefaults()
      },
    },
    'tripData.tripType.id'() {
      this.resetManagerOverridePrice()
    },
    'tripData.pricingMarket'() {
      this.resetManagerOverridePrice()
    },
    'tripData.marketplaceDiscountPercent'() {
      if (this.tripData.marketplaceDiscountPercent > 0) {
        this.marketplaceDiscountAdded = true
      }
    },
    'tripData.marketplaceMarkupPercent'() {
      if (this.tripData.marketplaceMarkupPercent > 0) {
        this.marketplaceMarkupAdded = true
      }
    },
    'tripData.paymentMethods'(newValue, oldValue) {
      if (!oldValue) {
        this.autoSetPaymentMethod()
      }
    },
    'quote.charterUpQuote'() {
      this.resetManagerOverridePrice()
    },
    'quote.leadsource.id'() {
      this.resetManagerOverridePrice()
    },
    recalculatePricing() {
      this.autoSelectThePrice()
    },
    refreshPricing: {
      immediate: true,
      handler: function (shouldRefreshPricing) {
        if (shouldRefreshPricing) {
          this.autoSelectThePrice()
          this.$emit('refresh-pricing')
        }
      },
    },
    showMarketplaceFeatures() {
      if (this.tripData?.paymentMethods) {
        if (!this.quoteDefaults?.paymentMethodType) {
          if (this.showMarketplaceFeatures) {
            this.autoSetPaymentMethod()
          } else {
            this.clearPaymentMethods()
          }
        }
      }
    },
    'currentTrip.tripVehicleGroups': {
      deep: true,
      handler: function () {
        this.setCustomerAccountDefaults()
      },
    },
  },
  mounted() {
    if (!this.tripData.rates) {
      this.$set(this.tripData, 'rates', [])
    }
    if (!this.tripData.charges) {
      this.$set(this.tripData, 'charges', [])
    }
    if (this.tripData.charges.length === 0) {
      // need at least one charge
      this.tripData.charges.push({ chargeType: { id: 1 }, amount: 0 })
    }

    const chargeTotal = this.tripData.charges.reduce(
      (total, charge) => total + parseFloat(charge.amount || 0),
      0
    )
    if (chargeTotal > 0) {
      this.hasNotUsedAutoPrice = false
    }
  },
  methods: {
    ...mapActions({
      getQuotePricing: 'quotes/getQuotePricing',
      setShouldSplitTrip: 'quotes/setShouldSplitTrip',
      setPaymentTypeIdModule: 'quoteForm/setPaymentTypeId',
      setDepositPercentageModule: 'quoteForm/setDepositPercentage',
    }),
    ...mapGetters({
      getSelectedMarket: 'quotes/getSelectedMarket',
      getIsManuallySelectedPricingMarket:
        'quotes/getIsManuallySelectedPricingMarket',
      getSelectedGarage: 'quotes/getSelectedGarage',
    }),
    async autoSelectThePrice() {
      if (typeof this.pricingDebounce !== 'undefined') {
        clearTimeout(this.pricingDebounce)
      }
      this.pricingDebounce = setTimeout(async () => {
        let quoteClone = deepClone(this.quote)
        quoteClone = scrubQuoteForAPI(quoteClone, true)
        quoteClone.pricingMarket = this.getSelectedMarket()(
          this.tripIndex
        )?.marketId

        //When viewing or editing the quote,
        //don't override existing trip pricing markets
        quoteClone.trips.forEach((trip) => {
          if (!!trip.active) {
            trip.isManuallySelectedPricingMarket =
            ['view', 'edit'].includes(this.mode) || trip.isManuallySelectedPricingMarket
          }
        })

        quoteClone.pricingGarageId = this.getSelectedGarage()(
          this.tripIndex
        )?.garageId
        const quotePricingResponse = await this.getQuotePricing(quoteClone)
          .then((data) => data.data)
          .catch((e) => e)
        const splitTrips = !!quotePricingResponse?.quoteShouldBeSplitIntoMultipleTrips
        const vehicleNeededEntireTrip = this.quote.trips[this.tripIndex]
          .vehicleNeededEntireTrip
        this.setShouldSplitTrip(splitTrips && !vehicleNeededEntireTrip)

        const result = quotePricingResponse?.pricings?.find(
          (pricing) =>
            pricing.autoSelected &&
            pricing.tripId === this.tripIndex &&
            (
              pricing.companyId === this.quote.leadSource.partnerCompanyId ||
              // If the lead source is a non-reseller, non-partner, we want to give the same
              // pricing that CharterUP would have received
              (pricing.companyId == 2 && !this.quote.leadSource.partnerCompanyId)
            )
        )

        if (result) {
          this.quote.trips[this.tripIndex].pricingMethod = result.pricingMethod
          this.quote.pricingTimeLabel = result.pricingTimeLabel
          const autoSelectPrice = result.priceHigh
          this.setFare(autoSelectPrice)
        }
      }, 1000)
    },
    setChargeTypeId(chargeIndex, chargeTypeId) {
      const charge = this.tripData?.charges?.[chargeIndex]
      if (charge) {
        charge.chargeType.id = chargeTypeId
      }
      if (this.isShuttleCreateQuoteEnabled) {
        EventBus.$emit('set-charge-type-id', chargeIndex, chargeTypeId)
      }
    },
    setChargeAmount(chargeIndex, amount) {
      const charge = this.tripData?.charges?.[chargeIndex]
      if (charge) {
        charge.amount = amount
      }
      if (this.isShuttleCreateQuoteEnabled) {
        EventBus.$emit('set-charge-amount', chargeIndex, amount)
      }
    },
    setRateTypeId(rateIndex, rateTypeId) {
      const rate = this.tripData?.rates?.[rateIndex]
      if (rate) {
        rate.rateType.id = rateTypeId
      }
      if (this.isShuttleCreateQuoteEnabled) {
        EventBus.$emit('set-rate-type-id', rateIndex, rateTypeId)
      }
    },
    setRateAmount(rateIndex, amount) {
      const rate = this.tripData?.rates?.[rateIndex]
      if (rate) {
        rate.amount = amount
      }
      if (this.isShuttleCreateQuoteEnabled) {
        EventBus.$emit('set-rate-amount', rateIndex, amount)
      }
    },
    setDepositPercentage(depositPercentage) {
      this.tripData.depositPercentage = depositPercentage
      if (this.isShuttleCreateQuoteEnabled) {
        EventBus.$emit('set-deposit-percentage', depositPercentage)
      }
    },
    setFare(fare) {
      const baseFare = this.tripData.charges.find(
        (charge) => charge.chargeType.id === 1
      )

      if (!baseFare) {
        this.tripData.charges.push({ chargeType: { id: 1 }, amount: fare })
      } else {
        baseFare.amount = fare
      }
      if (this.isShuttleCreateQuoteEnabled) {
        EventBus.$emit('set-fare', fare)
      }
      this.hasNotUsedAutoPrice = false
    },
    revert(chargeIndex) {
      if (this.useSinglePriceSelector) {
        this.managerOverrideUsed = true
      }
      this.hasNotUsedAutoPrice = true
    },
    addRate() {
      if (
        this.isShuttleCreateQuoteEnabled &&
        this.currentTrip?.tripVehicleGroups?.length
      ) {
        EventBus.$emit('add-rate')
      } else {
        this.tripData.rates.push({ rateType: {}, amount: undefined })
      }
    },
    addCharge() {
      if (
        this.isShuttleCreateQuoteEnabled &&
        this.currentTrip?.tripVehicleGroups?.length
      ) {
        EventBus.$emit('add-charge')
      } else {
        this.tripData.charges.push({ chargeType: {}, amount: undefined })
      }
    },
    addMarketPlaceDiscount() {
      this.tripData.marketplaceDiscountPercent = 5
      this.marketplaceDiscountAdded = true
    },
    addMarketPlaceMarkup() {
      this.tripData.marketplaceMarkupPercent = 0
      this.marketplaceMarkupAdded = true
    },
    removeMarketplaceDiscount() {
      this.marketplaceDiscountAdded = false
      this.tripData.marketplaceDiscountPercent = 0
      this.tripData.marketplaceDiscountCharge = 0
    },
    removeMarketplaceMarkup() {
      this.marketplaceMarkupAdded = false
      this.tripData.marketplaceMarkupPercent = 0
      this.tripData.marketplaceMarkupCharge = 0
    },
    removeRate(rateIndex) {
      if (
        this.isShuttleCreateQuoteEnabled &&
        this.currentTrip?.tripVehicleGroups?.length
      ) {
        EventBus.$emit('remove-rate', rateIndex)
      } else {
        this.tripData.rates.splice(rateIndex, 1)
      }
    },
    removeCharge(chargeIndex) {
      if (
        this.isShuttleCreateQuoteEnabled &&
        this.currentTrip?.tripVehicleGroups?.length
      ) {
        EventBus.$emit('remove-charge', chargeIndex)
      } else {
        this.tripData.charges.splice(chargeIndex, 1)
      }
    },
    validateForms() {
      const formNames = [
        'trip-rate-detail-form',
        'trip-charge-detail-form',
        'trip-payment-totals-form',
        'trip-processing-fee-charge-detail-form',
        'payment-select-table',
      ]
      this.checkPaymentMethodSelections()
      validateTripFormGroups.call(this, formNames, 'payment')
    },
    hasSelectedMethodOfPayment() {
      if (!this.paymentMethods) {
        return false
      }

      return this.paymentMethods.some(
        (paymentMethod) => paymentMethod.isAllowed === true
      )
    },
    checkPaymentMethodSelections(paymentMethodIndex = null, value = null) {
      if (
        this.isShuttleCreateQuoteEnabled &&
        paymentMethodIndex != null &&
        value !== null
      ) {
        EventBus.$emit('set-trip-payment-methods', paymentMethodIndex, value)
      }
      EventBus.$emit('recalculate-payment-methods')
      if (!this.hasSelectedMethodOfPayment()) {
        this.showPaymentMethodError = true
        this.$emit('validation-results', {
          tripIndex: this.tripIndex,
          tripModule: 'payment-method',
          hasFailures: true,
        })
      } else {
        this.showPaymentMethodError = false
        this.$emit('validation-results', {
          tripIndex: this.tripIndex,
          tripModule: 'payment-method',
          hasFailures: false,
        })
      }
    },
    setDueDate(dueDate) {
      if (this.isShuttleCreateQuoteEnabled) {
        EventBus.$emit('set-due-date', dueDate)
      }
      if (typeof dueDate === 'string') {
        this.$set(this.tripData, 'dueDate', dueDate)
      } else {
        this.$set(this.tripData, 'dueDate', dueDate.toISODate())
      }
      this.$forceUpdate()
    },
    paymentTypeChanged(paymentType) {
      if (!paymentType) {
        return
      }
      const foundPaymentType = this.tripData?.paymentType
      if (foundPaymentType) {
        foundPaymentType.id = paymentType
      }
      if (this.isShuttleCreateQuoteEnabled) {
        EventBus.$emit('set-payment-type-id', paymentType)
      }
      if (!paymentType && this.tripData?.paymentType?.id) {
        paymentType = this.tripData?.paymentType?.id
      }
      this.tripData.paymentTypeId = paymentType
      const { stops = [] } = this.tripData
      const firstStop = stops.find((stop) => stop.orderIndex === 0)
      if (firstStop) {
        let dueDate
        const { pickupDate } = firstStop
        if (paymentType === 2) {
          const today = DateTime.local()

          if (this.quote.charterUpQuote) {
            dueDate = DateTime.fromISO(pickupDate).minus({ days: 7 })
          } else {
            dueDate = DateTime.fromISO(pickupDate).minus({ days: 14 })
          }
          if (dueDate > today) {
            this.setDueDate(dueDate)
          }
        } else if (paymentType === 3) {
          dueDate = DateTime.fromSQL(pickupDate).plus({ days: 30 })
          this.setDueDate(dueDate)
        }
      }
      EventBus.$emit('recalculate-payment-methods')
    },
    editDiscount(value, charge) {
      if (value > 0) {
        charge.amount = -value
      } else {
        charge.amount = value
      }
    },
    resetManagerOverridePrice() {
      this.managerOverrideUsed = false
      if (this.shouldHitPricingEngine) {
        this.autoSelectThePrice()
      }
    },
    constrainDiscountPercent() {
      if (this.discountDebounce) {
        clearTimeout(this.discountDebounce)
      }

      this.discountDebounce = setTimeout(() => {
        if (this.tripData.marketplaceDiscountPercent > 100) {
          this.tripData.marketplaceDiscountPercent = 100
        } else if (this.tripData.marketplaceDiscountPercent < 0) {
          this.tripData.marketplaceDiscountPercent = 0
        } else {
          this.tripData.marketplaceDiscountPercent = Math.round(
            this.tripData.marketplaceDiscountPercent
          )
        }
      }, 500)
    },
    constrainMarkupPercent() {
      if (this.markupDebounce) {
        clearTimeout(this.markupDebounce)
      }

      this.markupDebounce = setTimeout(() => {
        if (this.tripData.marketplaceMarkupPercent > 100) {
          this.tripData.marketplaceMarkupPercent = 100
        } else if (this.tripData.marketplaceMarkupPercent < 0) {
          this.tripData.marketplaceMarkupPercent = 0
        } else {
          this.tripData.marketplaceMarkupPercent = Math.round(
            this.tripData.marketplaceMarkupPercent
          )
        }
      }, 500)
    },
    setPaymentTypeIdFromKey(key) {
      const paymentTypeId = this.paymentTypes.find((type) => type.key === key)
        ?.id
      if (!paymentTypeId) {
        return false
      }

      this.tripData.paymentType.id = paymentTypeId
      this.tripData.paymentTypeId = paymentTypeId
      return true
    },
    autoSetPaymentInformation() {
      if (this.minPickupDaysAway < 7.0) {
        if (!this.setPaymentTypeIdFromKey('full_payment')) {
          return
        }
      } else {
        if (!this.setPaymentTypeIdFromKey('down_payment')) {
          return
        }
        this.tripData.depositPercentage = 10
        if (this.earliestPickupDateTime !== null) {
          const dueDate = this.earliestPickupDateTime.minus({ days: 7 })
          this.setDueDate(dueDate)
        }
      }
      EventBus.$emit('recalculate-payment-methods')
    },
    setCustomerAccountDefaults() {
      if (!this.quoteDefaults) {
        return
      }
      if (this.quoteDefaults.paymentType?.key) {
        this.setPaymentTypeIdFromKey(
          this.quoteDefaults.paymentType.key
        )
        const paymentTypeId = this.paymentTypes.find(
          (type) => type.key === this.quoteDefaults.paymentType.key
        )?.id
        if (paymentTypeId) {
          EventBus.$emit('set-payment-type-id', paymentTypeId)
        }
      }
      if (
        this.quoteDefaults.downPaymentPercent != null && // coerce undefined to null
        this.quoteDefaults.downPaymentPercent >= 0 &&
        this.quoteDefaults.downPaymentPercent <= 100
      ) {
        this.tripData.depositPercentage = this.quoteDefaults.downPaymentPercent
        EventBus.$emit(
          'set-deposit-percentage',
          this.quoteDefaults.downPaymentPercent
        )
      }
      if (
        this.quoteDefaults?.paymentTermsDays &&
        this.quoteDefaults.paymentTermsDays >= 0
      ) {
        if (this.earliestPickupDateTime !== null) {
          const dueDate = this.earliestPickupDateTime.plus({
            days: this.quoteDefaults.paymentTermsDays,
          })
          this.setDueDate(dueDate)
          EventBus.$emit('set-due-date', dueDate)
        }
      }
    },
    disableAutoSetPaymentType() {
      this.tripData.autoSetPaymentType = false
    },
    autoSetPaymentMethod() {
      if (!this.tripData.paymentMethods) {
        return
      }
      const paymentMethodSet = this.tripData.paymentMethods.some(
        (method) => method.isAllowed
      )
      if (
        this.isQuotesAutoSetPaymentEnabled &&
        this.showMarketplaceFeatures &&
        !paymentMethodSet
      ) {
        const creditCardMethod = this.tripData.paymentMethods.find(
          (method) => method.paymentMethodType.key === 'credit_card'
        )
        creditCardMethod.isAllowed = true
      }
      this.checkPaymentMethodSelections()
    },
    createPaymentMethodsRefMap() {
      const refMap = {}

      if (this.$refs.paymentMethodCheckBoxes) {
        for (const ref of this.$refs.paymentMethodCheckBoxes) {
          refMap[ref.textContent.trim()] = ref
        }
      }

      return refMap
    },
    clearPaymentMethods() {
      if (this.isQuotesAutoSetPaymentEnabled) {
        // If refs are present, then they must be used to clear the checkboxes or else render errors occur
        if (this.$refs.paymentMethodCheckBoxes) {
          const paymentMethodsRefMap = this.createPaymentMethodsRefMap()
          this.tripData.paymentMethods
            .filter((method) => method.isAllowed)
            .forEach((method) => {
              const ref = paymentMethodsRefMap[method.paymentMethodType.label]
              ref.firstChild.click()
            })
        } else {
          this.tripData.paymentMethods.forEach((method) => {
            method.isAllowed = false
          })
          this.checkPaymentMethodSelections()
        }
      }
    },
  },
}
</script>

<style lang="scss">
.infobox {
  padding: 10px;
  color: $black;
  background-color: $white;
  border: 1px solid $black;
  border-radius: 5px;

  &.dark {
    color: $white;
    background-color: $black;
    border: 1px solid $white;
  }

  &:not(:first-child) {
    margin-top: 1em;
  }
}

.error-message {
  margin: 10px;
  font-size: 12px;
  color: $error !important;
}

.checkbox-label {
  margin-left: 6px;
  font-size: 12px;
}

.payment-method-checkbox-container {
  padding: 0;
}

.v-input--selection-controls {
  margin: 0;
}

.first-charge-amount {
  padding-right: 4px;
}

.suggested-pricing-container {
  justify-content: center;
  width: 300px;
  height: 40px;
  padding-top: 10px;
  margin-bottom: 10px;
  font-weight: 800;
  text-align: center;
  background-color: $gray;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 2px;

  .container-label {
    padding-left: 0;
    color: $gray-light;
  }

  .pricing-type {
    padding-left: 6px;
    color: $white;
  }

  .price {
    padding-left: 6px;
    color: $shofur;
  }
}
</style>
